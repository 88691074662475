import styled from 'styled-components';
import { Flex, Box } from 'rebass/styled-components';
//
import Media from '../../../utils/Media';

export const StyledHeading = styled(Flex)`
  justify-content: space-between;
  margin-top: 0px;
  ${Media({
    display: ['none', 'none', 'flex', 'flex'],
    width: ['100%', null, null, '80%'],
  })}
`;

export const StyledMobileHeading = styled(Flex)`
  margin-left: 1rem;
  margin-right: 1rem;
  ${Media({
    display: ['block', 'block', 'none', 'none'],
  })}
`;

export const StyledContainer = styled(Flex)`
  display: flex;
  overflow-x: hidden;
  max-width: ${props => props.theme.sizes.xl};
  ${Media({
    flexWrap: ['wrap', 'wrap', 'nowrap', 'nowrap'],
    marginTop: ['40px', null, null, '90px'],
  })}
`;

export const StyledLeftColumn = styled(Flex)`
  width: 40%;
  display: flex;
  flex-direction: column;
  position: realtive;

  ${Media({
    width: ['100%', '100%', '40%', '40%'],
    marginRight: ['0px', '0px', '1.5rem', '62px'],
  })}
`;

export const StyledRightColumn = styled(Flex)`
  ${Media({
    width: ['100%', '100%', '55%', '55%'],
    marginRight: ['1rem', '1rem', '0px', '0px'],
    marginLeft: ['1rem', '1rem', '0px', '0px'],
  })}
`;

export const StyledDescriptionContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledBlueBox = styled.div`
  position: relative;
  padding-left: 173px;
  padding-right: 0px;
  padding-bottom: 2rem;
  color: ${props => props.theme.ingredients.color};
  background-color: ${props => props.theme.ingredients.backgroundColor};
  min-height: 400px;
  font-size: 2rem;
  min-height: 717px;
  z-index: 3;
  padding-top: 2.5rem;
  font-size: 1rem;

  > div {
    a {
      color: ${props => props.theme.ingredients.color};
      &:hover {
        color: ${props => props.theme.ingredients.hoverColor};
      }
    }
    ul {
      padding-right: 1rem;
    }

    ol li p:empty {
      width: 30px;
      height: 30px;
    }

    ul {
      li {
        position: relative;
        padding-left: 2.25rem;
        p:empty {
          width: 30px !important;
          height: 30px !important;
        }
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 9px;
          display: block;
          width: 10px;
          height: 10px;
          background-color: ${props => props.theme.colors.white};
          font-size: 1rem;
        }
      }
    }
  }

  > div:first-child {
    h1:first-child,
    h2:first-child,
    h3:first-child,
    h4:first-child,
    h5:first-child,
    p:first-child {
      padding-top: 0px;
      border-top: 0px;
      margin-top: 0px;
    }
  }

  > div:last-child {
    h1:last-child,
    h2:last-child,
    h3:last-child,
    h4:last-child,
    h5:last-child,
    p:last-child {
      padding-top: 0px;
      border-top: 0px;
      margin-top: 0px;
    }
  }

  p {
    line-height: 1.88;
    padding-right: 1rem;
    ${Media({
      fontSize: ['1rem', '1rem', '1rem', '1rem'],
    })}
  }

  h3 {
    border-top: 1px solid ${props => props.theme.ingredients.color};
    padding-top: 2rem;
    margin-top: 2rem;

    &:first-child {
      border: none;
    }
  }

  ${Media({
    marginTop: [null, null, '2rem', '75px'],
    paddingLeft: ['50px', '50px', '40px', '185px'],
    display: ['none', 'none', 'block', 'block'],
  })}
`;

export const StyledBlueBoxMobile = styled.div`
  position: relative;
  margin-top: 2.625rem;
  color: white;
  background-color: ${props => props.theme.colors.primary};
  min-height: 400px;
  font-size: 2rem;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 2.5rem;
  font-size: 1rem;
  padding-bottom: 2rem;

  ${Media({
    marginRight: ['-1rem', '0px', null, null],
  })}

  > div {
    a {
      color: white;
      &:hover {
        color: ${props => props.theme.colors.secondary};
      }
    }

    ul {
      padding-right: 1rem;
    }

    ol li p:empty {
      width: 30px;
      height: 30px;
    }

    ul {
      li {
        position: relative;
        padding-left: 2.25rem;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 9px;
          display: block;
          width: 10px;
          height: 10px;
          background-color: ${props => props.theme.colors.white};
          font-size: 1rem;
        }
      }
    }
  }

  > div:first-child {
    h1:first-child,
    h2:first-child,
    h3:first-child,
    h4:first-child,
    h5:first-child,
    p:first-child {
      padding-top: 0px;
      border-top: 0px;
      margin-top: 0px;
    }
  }

  > div:last-child {
    h1:last-child,
    h2:last-child,
    h3:last-child,
    h4:last-child,
    h5:last-child,
    p:last-child {
      padding-top: 0px;
      border-top: 0px;
      margin-top: 0px;
    }
  }

  h3 {
    border-top: 1px solid ${props => props.theme.colors.white};
    padding-top: 2rem;
    margin-top: 2rem;

    &:first-child {
      border: none;
    }
  }

  ${Media({
    display: ['block', 'block', 'none', 'none'],
  })}
`;

export const DocumentDownloadContainer = styled.div`
  ${Media({
    display: ['none', 'none', 'none', 'block'],
    marginRight: ['1rem', null, null, '171px'],
    marginBottom: ['36px', null, '20px', '3.5rem'],
    marginTop: ['3.5rem', null, '2.5rem', '2.5rem'],
  })}
`;

export const DocumentDownloadContainerMobile = styled.div`
  ${Media({
    display: ['block', 'block', 'block', 'none'],
    marginRight: ['1rem', null, null, '171px'],
    marginBottom: ['36px', null, '20px', '3.5rem'],
    marginTop: ['3.5rem', null, '2.5rem', '2.5rem'],
  })}
`;

export const StyledHeadingText = styled.h1`
  font-size: 2rem;
  margin-bottom: 1rem;
  margin-top: 2.5rem;
  max-width: 450px;
  color: ${props => props.theme.colors.text};
  ${Media({
    marginTop: ['2.5rem', '2.5rem', '0px', '2.5rem'],
  })}
`;

export const StyledDescriptionText = styled(Box)`
  font-size: 1.25rem;
  color: ${props => props.theme.colors.text};
`;

export const StyledTextWrapper = styled.div`
  @media only screen and (min-width: 1350px) {
    .textHighlight p,
    .textHighlight h1,
    .textHighlight h2,
    .textHighlight h3,
    .textHighlight h4,
    .textHighlight h5 {
      ${Media({
        marginRight: ['0px', null, null, '170px'],
      })}
    }

    li > p,
    h1,
    h2,
    h3,
    h4,
    h5 {
      ${Media({
        marginRight: ['0px', null, null, '280px'],
      })}
    }

    p,
    h1,
    h2,
    h3,
    h4,
    h5 {
      ${Media({
        marginRight: ['0px', null, null, '170px'],
      })}
    }
  }

  padding-right: 1rem;

  .media {
    margin: 0;
  }
`;

export const StyledFeaturedCertification = styled.div`
  div {
    width: 72px;
    height: 72px;
  }

  ${Media({
    display: ['none', 'none', 'none', 'block'],
  })}
`;

export const StyledFeaturedCertificationMobile = styled.div`
  max-width: 1440px;
  margin-bottom: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 10px;

  div {
    width: 72px;
    height: 72px;
    display: block;
    margin-left: auto;
  }

  ${Media({
    display: ['none', 'none', 'block', 'none'],
  })}
`;

export const StyledHeaderInfo = styled.div`
  margin-right: 2rem;
`;

export const StyledImageWrapper = styled.div`
  ${Media({
    width: ['auto', 'auto', 'auto', '390px'],
    margin: ['0px 1rem 0px 1rem', '0px 1rem 0px 1rem', '0px 0px 0px 2rem', '0px 0px 0px auto'],
  })}
`;
