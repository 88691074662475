/* eslint-disable react/require-default-props */
import React from 'react';

import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import {
  StyledContainer,
  StyledLeftColumn,
  StyledRightColumn,
  StyledDescriptionContainer,
  StyledBlueBox,
  StyledBlueBoxMobile,
  StyledHeading,
  StyledMobileHeading,
  DocumentDownloadContainer,
  DocumentDownloadContainerMobile,
  StyledHeadingText,
  StyledDescriptionText,
  StyledTextWrapper,
  StyledFeaturedCertification,
  StyledFeaturedCertificationMobile,
  StyledHeaderInfo,
  StyledImageWrapper,
} from './Styled';
import RichText, { RichTextProps } from '../../RichText';
import DocumentDownload, { DocumentDownloadProps } from '../../DocumentDownload';
import ProductCertificationList, {
  ICertification,
  renderCertificationListItem,
  renderCertificationLink,
} from '../../Product/ProductCertificationList/ProductCertificationList';
import { ProductCardModuleProps } from '../../Modules/ProductCardModule';
import { CtaSectionModuleProps } from '../../Modules/CtaSectionModule';
import { ProductCardModule, CtaSectionModule } from '../../Modules';

export type collection = {
  __typename: string;
  title: string;
  shortDescription?: string;
  cta?: any;
};

interface ProductPageProps {
  title: string;
  image: {
    title: string;
    alt?: string;
    asset: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  shortDescription: string;
  contentsSection: RichTextProps;
  attributes?: RichTextProps;
  document?: DocumentDownloadProps;
  additionalDocuments?: DocumentDownloadProps[];
  certification: Array<ICertification>;
  relatedProducts?: ProductCardModuleProps;
  ctaModule?: CtaSectionModuleProps;
}

const renderFirstCertificationItem = (certification: Array<ICertification>) =>
  certification &&
  certification[0] && (
    <StyledFeaturedCertification>
      {certification[0].to
        ? renderCertificationLink(certification[0].imageUrl, certification[0].title, certification[0].to, false)
        : renderCertificationListItem(certification[0].imageUrl, certification[0].title, false)}
    </StyledFeaturedCertification>
  );

const renderFeaturedCertification = (certification: Array<ICertification>) =>
  certification &&
  certification[0] && (
    <StyledFeaturedCertification>{renderFirstCertificationItem(certification)}</StyledFeaturedCertification>
  );

const renderFeaturedCertificationMobile = (certification: Array<ICertification>) =>
  certification &&
  certification[0] && (
    <StyledFeaturedCertificationMobile>{renderFirstCertificationItem(certification)}</StyledFeaturedCertificationMobile>
  );

const ProductPage = ({
  title,
  image,
  shortDescription,
  contentsSection,
  attributes,
  document,
  additionalDocuments,
  certification,
  relatedProducts,
  ctaModule,
}: ProductPageProps) => {
  return (
    <>
      {renderFeaturedCertificationMobile(certification)}
      <StyledContainer id="product-cotnainer">
        <StyledLeftColumn>
          <StyledImageWrapper>
            {image?.asset && (
              // <Img
              //   fluid={image.asset.fluid}
              //   alt={image.alt}
              //   style={{ maxHeight: '500px' }}
              //   imgStyle={{ objectFit: 'contain' }}
              // />
              <GatsbyImage image={image.asset.gatsbyImageData} alt={image.alt ? image.alt : image.title} />
            )}
          </StyledImageWrapper>
          <StyledMobileHeading>
            <StyledHeadingText>{title}</StyledHeadingText>
            <StyledDescriptionText>{shortDescription}</StyledDescriptionText>
          </StyledMobileHeading>
          {attributes && (
            <StyledBlueBox>
              <RichText {...attributes} />
            </StyledBlueBox>
          )}
        </StyledLeftColumn>
        <StyledRightColumn>
          <StyledDescriptionContainer>
            <StyledHeading>
              <StyledHeaderInfo>
                <StyledHeadingText>{title}</StyledHeadingText>
                <StyledDescriptionText>{shortDescription}</StyledDescriptionText>
              </StyledHeaderInfo>
              {renderFeaturedCertification(certification)}
            </StyledHeading>
            <StyledTextWrapper>
              <RichText {...contentsSection} />
            </StyledTextWrapper>
            {attributes && (
              <StyledBlueBoxMobile>
                <RichText {...attributes} />
              </StyledBlueBoxMobile>
            )}
            {document && (
              <>
                <DocumentDownloadContainer>
                  <DocumentDownload {...document} />
                </DocumentDownloadContainer>
                <DocumentDownloadContainerMobile>
                  <DocumentDownload {...document} renderMobileVariant />
                </DocumentDownloadContainerMobile>
              </>
            )}
            {additionalDocuments &&
              Array.isArray(additionalDocuments) &&
              additionalDocuments.map(doc => (
                <div key={doc.id}>
                  <DocumentDownloadContainer>
                    <DocumentDownload {...doc} />
                  </DocumentDownloadContainer>
                  <DocumentDownloadContainerMobile>
                    <DocumentDownload {...doc} renderMobileVariant />
                  </DocumentDownloadContainerMobile>
                </div>
              ))}
            {certification && !!certification.length && <ProductCertificationList certifications={certification} />}
          </StyledDescriptionContainer>
        </StyledRightColumn>
      </StyledContainer>
      {relatedProducts && relatedProducts.products && relatedProducts.products.length > 0 ? (
        <ProductCardModule {...relatedProducts} greyVariant />
      ) : null}
      {ctaModule ? <CtaSectionModule {...ctaModule} /> : null}
    </>
  );
};

export default ProductPage;
