import {
  CtaSectionModule,
  EventListingModule,
  FindADealerModule,
  HeroSectionModule,
  InfoSectionModule,
  LatestNewsModule,
  NewsListingModule,
  PastEventsModule,
  ProductListingModule,
  CategoriesModule,
  ProductListingTableModule,
  SearchResultsModule,
  ProductCardModule,
  UpcomingEventsModule,
  CategoryListingModule,
  DocumentDownloadModule,
  ImageAndTextModule,
  ShortBannerModule,
  MediaModule,
  TableModule,
  CustomBlocksModule,
  BrandListingModule,
  AdviceListingModule,
  AdviceCardModule,
  ContactsModule,
} from '../../Modules';

import { CenteredRichText } from '../../RichText';

const componentMap: Record<string, any> = {
  AdviceListingSection: AdviceListingModule,
  AdvicesSection: AdviceCardModule,
  ContactsSection: ContactsModule,
  CtaSection: CtaSectionModule,
  EventsListingSection: EventListingModule,
  FindADealerSection: FindADealerModule,
  HeroSection: HeroSectionModule,
  InfoSection: InfoSectionModule,
  LatestNewsSection: LatestNewsModule,
  NewsListingSection: NewsListingModule,
  PastEventsSection: PastEventsModule,
  ProductCategoriesSection: CategoriesModule,
  ProductListingSection: ProductListingModule,
  ProductsServicesSection: ProductCardModule,
  ProductsListingTableSection: ProductListingTableModule,
  SearchResultsSection: SearchResultsModule,
  Text: CenteredRichText,
  UpcomingEvents: UpcomingEventsModule,
  CategoryListingSection: CategoryListingModule,
  DocumentDownloadSection: DocumentDownloadModule,
  ImageAndText: ImageAndTextModule,
  ShortBanner: ShortBannerModule,
  Media: MediaModule,
  Table: TableModule,
  CustomBlocksSection: CustomBlocksModule,
  BrandListing: BrandListingModule,
};

export default componentMap;
