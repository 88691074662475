import styled from 'styled-components';
import { Flex } from 'rebass/styled-components';
//
import Media from '../../../utils/Media';

export const StyledContainer = styled(Flex)`
  display: flex;

  ${Media({
    flexWrap: ['wrap', 'wrap', 'nowrap', 'nowrap'],
  })}
`;

export const StyledHeading = styled.div`
  font-size: 3.5rem;
  color: ${props => props.theme.colors.text};
  margin-bottom: 1.5rem;
  marginleft: 1.25rem;
  marginright: 1.25rem;
`;

export const StyledDescription = styled.div`
  font-size: 1.25rem;
  color: ${props => props.theme.colors.text};
  margin-bottom: 80px;
`;

export const StyledHeaderContainer = styled.div`
  margin: 0 auto;
`;

export const StyledCenteredText = styled.div`
  ${Media({
    width: ['auto', 'auto', 'auto', '645px'],
    margin: ['48px auto auto 20px', '48px auto auto 20px', '48px auto auto 214px', '57px auto'],
  })}
  blockquote {
    ${Media({
      width: ['auto', 'auto', 'auto', '1042px'],
    })}
  }
`;

export const StyledModuleContainer = styled.div`
  ${Media({
    flexWrap: ['wrap', 'wrap', 'nowrap', 'nowrap'],
  })}
`;

export const DocumentDownloadContainer = styled.div`
  margin-top: 64px;
  max-width: 420px;
  ${Media({
    marginRight: ['2rem', '2rem'],
  })}
`;
