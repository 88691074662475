import { getSrc } from 'gatsby-plugin-image';
import * as React from 'react';

import {
  ContentfulMenuItem,
  Maybe,
  ContentfulSettings,
  ContentfulImage,
  ContentfulBasicPage,
  ContentfulEvent,
  ContentfulNews,
  ContentfulAdvice,
  ContentfulProduct,
} from '../../generated/gatsby.types';
import { MainLayout } from '../../ui-lib/components';
import { getIntlSlug } from '../../ui-lib/utils/common';
import richTextRawToJson from '../../utils/contentful-rich-text-raw-to-json';
import handleNewsletterFormSubmission from '../../utils/handleNewsletterFormSubmission';

const dontPrefixPaths = process.env.DONT_PREFIX_PATHS_LOCALE === 'true';

type NavItemType = {
  to: string;
  label: string;
  children?: Array<NavItemType>;
};

type LayoutProps = {
  pageLocale: string;
  pageType: string;
  pageTitle?: string;
  pageDescription?: string;
  siteUrl: string;
  pageSlug: string;
  contentfulSettings: ContentfulSettings;
  pageSettings?: any;
  pageImage?: ContentfulImage;
  swiftTypeDate?: string;
  swiftTypeLocation?: string;
  children: React.ReactNode;
  cookieBannerTitle: string;
  cookieBannerDescription: any;
  updatedAt: string;
  showFooterSubscribeForm: boolean;
  showFooterSubscribeFormCountryField: boolean;
  footerSubscribeFormHeading?: any | null;
  flexmailId?: string | null;
  locale: string;
  ogPageType: string;
  isHomepage: boolean;
  translatedSlugs: Array<ContentfulBasicPage | ContentfulEvent | ContentfulNews | ContentfulAdvice | ContentfulProduct>;
};

const buildCanonicalLink = (extractedPageSettings: any, siteUrl: string, locale: string, slug: string) => {
  const pagePath = dontPrefixPaths ? slug : `${locale}/${slug}`;

  return extractedPageSettings && extractedPageSettings.canonical
    ? extractedPageSettings.canonical
    : `${siteUrl}/${pagePath}`;
};

const buildMenuItem = (item: any) => {
  const menuitem: NavItemType = { to: '', label: '', children: [] };

  if (item?.title) {
    menuitem.label = item?.title || '';
    if (item?.externalUrl) {
      menuitem.to = item.externalUrl;
    } else if (item.navigationElement?.slug) {
      const { slug } = item.navigationElement;
      menuitem.to = slug === 'homepage' ? '/' : slug;
    }
  }

  return menuitem;
};

const buildMenuNavigation = (contentfulNav: Maybe<ContentfulMenuItem>[] | null | undefined, secondary = false) => {
  const navigation: Array<NavItemType> =
    contentfulNav?.map(item => {
      const menuitem: NavItemType = buildMenuItem(item);

      if (item?.childrens && secondary) {
        item.childrens.forEach(children => {
          const child: NavItemType = buildMenuItem(children);
          if (child && menuitem && menuitem.children) {
            menuitem.children.push(child);
          }
        });
      }

      return menuitem;
    }) || [];

  return navigation;
};

const extractSiteDescription = (item: any) => (item.text ? item.text : item);

const getPageSettings = (pageSettings: any) => {
  if (!pageSettings) {
    return null;
  }

  const { title, description, nofollow, noindex, canonical, keywords } = pageSettings;

  return {
    title,
    description,
    nofollow,
    noindex,
    canonical,
    keywords,
  };
};

const extractImage = (image: any) => {
  if (!image) {
    return null;
  }

  // if (image && image.socialImage && image.socialImage.fixed && image.socialImage.fixed.src) {
  //   return image.socialImage.fixed.src;
  // }
  if (image?.socialImage?.gatsbyImageData) {
    return getSrc(image?.socialImage?.gatsbyImageData);
  }

  // if (image && image.asset && image.asset.fixed && image.asset.fixed.src) {
  //   return image.asset.fixed.src;
  // }
  if (image?.asset?.gatsbyImageData) {
    return getSrc(image?.asset?.gatsbyImageData);
  }

  return null;
};

const getSiteData = (
  pageTitle: string,
  pageDescription: string,
  pageLocale: string,
  pageType: string,
  siteUrl: string,
  pageSlug: string,
  data: ContentfulSettings,
  isHomepage: string,
  ogPageType: string,
  pageSettings?: any,
  pageImage?: ContentfulImage | undefined,
  swiftTypeDate?: string,
  swiftTypeLocation?: string,
  updatedAt?: string,
) => {
  const {
    siteDescription,
    siteImage,
    footerOffices,
    headerNavigation,
    footerMainNavigation,
    footerSecondaryNavigation,
    footerSocialLinks,
  } = data;

  const extractedPageSettings = getPageSettings(pageSettings);

  const socialLinks = footerSocialLinks?.map(link => {
    return {
      label: link?.title,
      to: link?.url,
    };
  });
  const siteData = {
    metaData: {
      pageLocale,
      pageType,
      updatedAt,
      siteName: data.siteTitle,
      title: pageTitle || (extractedPageSettings && extractedPageSettings.title ? extractedPageSettings.title : null),
      description:
        pageDescription ||
        (extractedPageSettings && extractedPageSettings.description
          ? extractedPageSettings.description
          : extractSiteDescription(siteDescription)),
      image: pageImage ? extractImage(pageImage) : extractImage(siteImage),
      swiftTypeDate,
      swiftTypeLocation,
      nofollow: extractedPageSettings && extractedPageSettings.nofollow ? extractedPageSettings.nofollow : undefined,
      noindex: extractedPageSettings && extractedPageSettings.noindex ? extractedPageSettings.noindex : undefined,
      canonical: isHomepage
        ? `${siteUrl}/${pageLocale}`
        : buildCanonicalLink(extractedPageSettings, siteUrl, pageLocale, pageSlug),
      ogPageType,
      searchTags: extractedPageSettings?.keywords,
    },
    header: {
      navigation: buildMenuNavigation(headerNavigation, true),
    },
    footer: {
      contacts: (footerOffices && richTextRawToJson(footerOffices, pageLocale)) || '',
      navigation: buildMenuNavigation(footerMainNavigation),
      legalLinks: buildMenuNavigation(footerSecondaryNavigation),
      socialLinks,
    },
  };

  return siteData;
};

const Layout = ({
  pageTitle,
  pageDescription,
  pageLocale,
  pageType,
  siteUrl,
  pageSlug,
  contentfulSettings,
  pageSettings,
  swiftTypeDate,
  swiftTypeLocation,
  children,
  pageImage,
  cookieBannerTitle,
  cookieBannerDescription,
  showFooterSubscribeForm = true,
  showFooterSubscribeFormCountryField,
  subscribeFormHeading = null,
  flexmailId = null,
  updatedAt,
  ogPageType,
  isHomepage,
  translatedSlugs,
}: LayoutProps) => {
  const siteData = getSiteData(
    pageTitle,
    pageDescription,
    pageLocale,
    pageType,
    siteUrl,
    pageSlug,
    contentfulSettings,
    isHomepage,
    ogPageType,
    pageSettings,
    pageImage,
    swiftTypeDate,
    swiftTypeLocation,
    updatedAt,
  );

  const headerVariant = contentfulSettings.headerVariant || '';

  const searchSlug = getIntlSlug(contentfulSettings.searchPage?.slug || '');

  const handleSearch = (q: string) => {
    // eslint-disable-next-line no-undef
    if (window) {
      // eslint-disable-next-line no-undef
      window.location.href = `${searchSlug}?q=${q}`;
    }
  };

  let translatedSlugsCopy: Array<
    ContentfulBasicPage | ContentfulEvent | ContentfulNews | ContentfulAdvice | ContentfulProduct
  > = [];

  if (translatedSlugs) {
    translatedSlugsCopy = [...translatedSlugs];

    if (isHomepage) {
      translatedSlugsCopy = translatedSlugsCopy.map(translation => {
        // eslint-disable-next-line no-param-reassign
        translation.slug = '';
        return translation;
      });
    }
  }

  return (
    <MainLayout
      siteData={siteData}
      searchPreviewImage={pageImage}
      headerSearchFunction={handleSearch}
      loadCookieBanner={!process.env.ONE_TRUST_ID}
      cookieBannerTitle={cookieBannerTitle}
      cookieBannerDescription={{
        data: cookieBannerDescription ? richTextRawToJson(cookieBannerDescription, pageLocale).json : null,
      }}
      showFooterSubscribeForm={showFooterSubscribeForm}
      showFooterSubscribeFormCountryField={showFooterSubscribeFormCountryField}
      subscribeFormHeading={subscribeFormHeading}
      flexmailId={flexmailId}
      headerVariant={headerVariant.toLowerCase()}
      onSubscribeFormSubmit={handleNewsletterFormSubmission}
      pageLocale={pageLocale}
      translatedSlugs={translatedSlugsCopy}
    >
      {children}
    </MainLayout>
  );
};

export default Layout;
