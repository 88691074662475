import React from 'react';

import {
  StyledContainer,
  StyledHeaderContainer,
  StyledCenteredText,
  StyledModuleContainer,
  DocumentDownloadContainer,
} from './Styled';
import RichText, { RichTextProps } from '../../RichText';
import { ProductCardModuleProps } from '../../Modules/ProductCardModule';
import { InfoSectionModule, NewsListingModule, HeroSectionModule, ProductCardModule } from '../../Modules';
import { NewsListingModuleProps } from '../../Modules/NewsListingModule';

import DocumentDownload, { DocumentDownloadProps } from '../../DocumentDownload';

export type collection = {
  __typename: string;
  title: string;
  shortDescription?: string;
  cta?: any;
};

interface NewsPageProps {
  title: string;
  shortDescription?: string;
  date?: string;
  contentsSection: RichTextProps;
  image: any;
  featuredProducts?: ProductCardModuleProps;
  newsSection?: NewsListingModuleProps;
  documentDownload?: DocumentDownloadProps;
}

const NewsPage = ({
  title,
  shortDescription,
  contentsSection,
  image,
  featuredProducts,
  newsSection,
  documentDownload,
}: NewsPageProps) => {
  return (
    <>
      <StyledContainer>
        <StyledHeaderContainer>
          <HeroSectionModule title={title} shortDescription={shortDescription} />
        </StyledHeaderContainer>
      </StyledContainer>
      <InfoSectionModule image={image} />
      <StyledCenteredText>
        <RichText {...contentsSection} />
        {documentDownload && (
          <DocumentDownloadContainer>
            <DocumentDownload
              documentTitle={documentDownload.documentTitle}
              documentType={documentDownload.documentType}
              fileSize={documentDownload.fileSize}
              downloadLink={documentDownload.downloadLink}
              showDocumentInfo
            />
          </DocumentDownloadContainer>
        )}
      </StyledCenteredText>
      <StyledModuleContainer data-appsearch-ignore="true">
        {featuredProducts && <ProductCardModule {...featuredProducts} greyVariant />}
        {newsSection && <NewsListingModule {...newsSection} />}
      </StyledModuleContainer>
    </>
  );
};

export default NewsPage;
