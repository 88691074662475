import resolveResponse from 'contentful-resolve-response';

/**
 * Detect if a reference has a rich text field
 */
const hasRichText = (reference: any) => {
  return Object.keys(reference).find(key => {
    return reference[key] && Object.keys(reference[key]).find(fieldKey => fieldKey === 'raw');
  });
};

/**
 * Expand all rich text fields inside a reference
 */
const expandRichTextFields = (reference: any, locale: string) =>
  Object.keys(reference).reduce((result, key) => {
    const newResult = { ...result };
    if (newResult[key]?.raw) {
      newResult[key] = {
        ...newResult[key],
        // eslint-disable-next-line no-use-before-define
        ...resolve(newResult[key], locale)?.json,
      };

      delete newResult[key].raw;
      delete newResult[key].references;
    }

    return newResult;
  }, reference);

const resolve = (valueToResolve: any, locale: string) => {
  if (!valueToResolve) {
    return undefined;
  }
  const { raw, references } = valueToResolve;
  const richText = JSON.parse(raw);

  // If no references are given, there is no need to resolve them
  if (!references || !references.length) {
    return { json: richText };
  }

  // Create dummy response so we can use official libraries for resolving the
  // entries
  const dummyResponse = {
    items: [
      {
        sys: { type: `Entry` },
        richText,
      },
    ],
    includes: {
      Entry: references
        .filter(({ __typename }: any) => __typename !== `ContentfulAsset`)
        .map((reference: any) => {
          if (hasRichText(reference)) {
            return expandRichTextFields(reference, locale);
          }

          return reference;
        })
        .map((reference: any) => {
          return {
            ...reference,
            sys: { ...reference.sys, type: `Entry`, id: reference.contentful_id },
          };
        }),
      Asset: references
        .filter(({ __typename }: any) => __typename === `ContentfulAsset`)
        .map((reference: any) => {
          return {
            ...reference,
            sys: { type: `Asset`, id: reference.contentful_id },
          };
        }),
    },
  };

  const resolved = resolveResponse(dummyResponse, {
    removeUnresolved: true,
  });

  // Create legacy format, unset json key
  const result = resolved[0].richText;

  // WIP: Try to cope without this tomfoolery
  // result.content = result.content.map((item: any) => {
  //   if (item?.data?.target) {
  //     // Process
  //     const { sys, metadata, ...fields } = item.data.target;

  //     const processedFields = Object.keys(fields).reduce((acc: Record<string, any>, fieldKey: string) => {
  //       if (!['__typename', 'contentful_id'].includes(fieldKey)) {
  //         acc[fieldKey] = {
  //           [locale]: fields[fieldKey],
  //         };
  //       }

  //       return acc;
  //     }, {});

  //     return {
  //       ...item,
  //       data: {
  //         ...item.data,
  //         target: {
  //           fields: processedFields,
  //           metadata,
  //           sys,
  //         },
  //       },
  //     };
  //   }

  //   return item;
  // });

  return { json: result };
};

export default resolve;
